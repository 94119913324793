<template>
  <div class="main">
    <div class="main_box">
      <div class="main_title">
        <h2>{{dataObj.title}}</h2>
        <div class="flex_center main_info">
          <div class="flex main_info_l">
            <!--<div class="main_info_l_tag">福建省</div>
            <div class="main_info_l_tag">2023年5月</div>-->
          </div>
          <p class="main_info_r">【阅读次数：{{dataObj.hits}} 】</p>
        </div>
      </div>
      <div class="flex_center main_operation">
        <!--<el-link type="primary" class="main_operation_item">在线查看</el-link>
        <el-link type="primary" class="main_operation_item">下载原文档</el-link>-->
        <el-button size="mini" :icon="isCollect ? 'el-icon-star-on' : 'el-icon-star-off'" class="main_operation_item" :style="isCollect ? 'color: #ffc94b;' : ''" @click="getCommonCollectionAddOrDel">收藏</el-button>
      </div>
      <div class="flex main_content">
        <div class="main_content_html" v-html="bodyData === '' ? '' : bodyData">内容</div>
        <el-tree class="main_content_tree" :data="treeNodeJson" :props="defaultProps" highlight-current current-node-key="1" v-if="!treeNodeJson" @node-click="handleNodeClick"></el-tree>
      </div>
    </div>
  </div>
</template>

<script>
  import { urlCecnNewsDetail, urlCommonCollectionAddOrDel, urlCommonCollectionCheck } from '@/api/engineering'
  export default {
    name: "quotaDetails",
    data() {
      return {
        treeNodeJson: [],
        defaultProps: {
          children: 'childs',
          label: 'title'
        },
        bodyData: '',
        dataObj: {},
        isCollect: false,
      }
    },
    mounted() {
      const { detailId, bid, isTabs } = this.$route.query
      this.bid = bid
      this.getCommonCollectionCheck()
      this.getCecnNewsDetail(detailId)
    },
    methods: {
      async getCommonCollectionCheck(bid) {
        const param = {
          bid: this.bid,
          type: "costConsultarticle"
        }
        const res = await urlCommonCollectionCheck(param)
        this.isCollect = res.data
      },
      async getCommonCollectionAddOrDel() {
        const param = {
          bid: this.bid,
          type: "costConsultarticle"
        }
        await urlCommonCollectionAddOrDel(param)
        this.isCollect = !this.isCollect
      },
      async handleNodeClick(data) {
        const param = `detailId=${data.detailId}`
        const res = await urlCecnNewsDetail(param)
        this.bodyData = res.data.body
      },
      async getCecnNewsDetail(detailId) {
        const param = `detailId=${detailId}`
        const res = await urlCecnNewsDetail(param)
        const obj = res.data?.treeNodeJson && JSON.parse(res.data?.treeNodeJson)
        if (obj) this.treeNodeJson = obj?.childs[0]?.childs
        this.dataObj = res.data
        this.bodyData = res.data?.body || ''
      }
    }
  }
</script>

<style scoped lang="less">
  .main {
    text-align: left;
    .main_box {
      .main_title {
        border-bottom: 1px solid #DCDCDC;
        margin-bottom: 20px;
        > h2 {
          font-size: 20px;
          font-weight: bold;
          color: #212121;
          padding-bottom: 20px;
        }
        .main_info {
          justify-content: space-between;
          padding-bottom: 20px;
          .main_info_l {
            margin: 0 -6px;
            .main_info_l_tag {
              height: 28px;
              padding: 0 6px;
              margin: 0 6px;
              background-color: #F6F7FB;
              border-radius: 4px;
              font-size: 16px;
              color: #4E4E4E;
              line-height: 28px;
            }
          }
          .main_info_r {
            font-size: 14px;
            color: #4E4E4E;
          }
        }
      }
      .main_operation {
        justify-content: flex-end;
        padding-bottom: 20px;
        .main_operation_item {
          margin-left: 20px;
        }
      }
      .main_content {
        min-height: 600px;
        .main_content_html {
          flex: 1;
        }
        .main_content_tree {
          width: 320px;
        }
      }
    }
  }
</style>